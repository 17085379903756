.input-class-first {
  color: #505050;
  font-size: small;
  width: 125px;
  border: 1px solid rgb(199, 199, 199);
  border-right: 0px;
  padding: 3px;
  border-radius: 4px 0px 0px 4px;
  text-align: center;
  height: 37px;
  z-index: 0;
  margin-left: 20px;
}

.input-class-last {
  color: #505050;
  font-size: small;
  width: 125px;
  border: 1px solid rgb(199, 199, 199);
  border-left: 0px;
  padding: 3px;
  border-radius: 0px 4px 4px 0px;
  text-align: center;
  height: 37px;
  z-index: 0;
}

.react-datepicker-popper {
  z-index: 9999;
}
